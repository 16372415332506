import Vue from "vue";
import VueRouter from "vue-router";

//Adding layouts router.
const AdminLayout = () => import("@/layouts/AdminLayout");

//Adding page content router.

// LOGIN ROUTES
const SignIn = () => import("@/views/Auth/SignIn");

// Dashboard ROUTES
const Dashboard = () => import("@/views/Dashboards/Dashboard");

// Courses ROUTES
const Courses = () => import("@/views/Courses/Courses");

// Departments ROUTES
const Departments = () => import("@/views/Departments/Departments");

// Branches ROUTES
const Branches = () => import("@/views/Branches/Branches");

// SubBranches ROUTES
const SubBranches = () => import("@/views/SubBranches/SubBranches");

// Centres ROUTES
const Centres = () => import("@/views/Centres/Centres");
const CentreStudents = () => import("@/views/Centres/Students");

// Sessions ROUTES
const Sessions = () => import("@/views/Sessions/Sessions");

// Student ROUTES
const Students = () => import("@/views/Students/Students");
const AddStudent = () => import("@/views/Students/AddStudent");
const EditStudent = () => import("@/views/Students/EditStudent");
const StudentDetails = () => import("@/views/Students/StudentDetails");
const StudentFiles = () => import("@/views/Students/StudentFiles");
const StudentPayments = () => import("@/views/Students/StudentPayments");

// Reports ROUTES
const StudentReport = () => import("@/views/Reports/StudentReport");

// Applied Documents ROUTES
const AppliedDocuments = () => import("@/views/AppliedDocuments/AppliedDocuments");

Vue.use(VueRouter);

const childRoute = () => [
	{
		path: "",
		name: "dashboard",
		meta: { name: "Dashboard" },
		component: Dashboard,
	},

	{
		path: "/courses",
		name: "courses",
		meta: { name: "Courses" },
		component: Courses,
	},

	{
		path: "/departments",
		name: "departments",
		meta: { name: "Departments" },
		component: Departments,
	},

	{
		path: "/branches",
		name: "branches",
		meta: { name: "Branches" },
		component: Branches,
	},
	
	{
		path: "/sub-branches",
		name: "subbranches",
		meta: { name: "SubBranches" },
		component: SubBranches,
	},

	{
		path: "/centres",
		name: "centres",
		meta: { name: "Centres" },
		component: Centres,
	},

	{
		path: "/centres/students/:id",
		name: "centres.students",
		meta: { name: "CentreStudents" },
		component: CentreStudents,
	},

	{
		path: "/sessions",
		name: "sessions",
		meta: { name: "Sessions" },
		component: Sessions,
	},

	{
		path: "/students",
		name: "students",
		meta: { name: "Students" },
		component: Students,
	},

	{
		path: "/students/add",
		name: "students.add",
		meta: { name: "AddStudent" },
		component: AddStudent,
	},

	{
		path: "/students/edit/:id",
		name: "students.edit",
		meta: { name: "EditStudent" },
		component: EditStudent,
	},

	{
		path: "/students/details/:id",
		name: "students.details",
		meta: { name: "StudentDetails" },
		component: StudentDetails,
	},

	{
		path: "/students/files/:id",
		name: "students.files",
		meta: { name: "StudentFiles" },
		component: StudentFiles,
	},

	{
		path: "/students/payments/:id",
		name: "students.payments",
		meta: { name: "StudentPayments" },
		component: StudentPayments,
	},

	{
		path: "/reports/students",
		name: "reports.students",
		meta: { name: "StudentReport" },
		component: StudentReport,
	},
	
	{
		path: "/applied-documents",
		name: "applied.documents",
		meta: { name: "AppliedDocuments" },
		component: AppliedDocuments,
	},
];
const authChildRoute = () => [
	{
		path: "sign-in",
		name: "auth.login",
		meta: { name: "SignIn" },
		component: SignIn,
	},
];

const routes = [
	{
		path: "/dashboard",
		name: "dashboard",
		component: AdminLayout,
		children: childRoute(),
	},
	{
		path: "/",
		name: "auth",
		component: SignIn,
		children: authChildRoute(),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.VUE_APP_BASE_URL,
	routes,
});

export default router;
