import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import axios from 'axios'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import VueToastify from "vue-toastify"
import moment from 'moment'


import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDmehs_u8H6kgD9d9aVV38RuAS-GSZT598",
    libraries: 'places'
  },
  installComponents: true,
});


Vue.use(VueToastify, {
	position: "top-right",
	canTimeout: true,
	defaultTitle: true,
	theme: "dark",
	orderLatest: true,
	maxToasts: 1,
	errorDuration: 8000,
	successDuration: 4000,
	warningInfoDuration: 4000
});

Vue.config.productionTip = false
Vue.prototype.$axios = axios.create({
  baseURL: 'https://api.indianeducation.co.in',
});
Vue.prototype.$image_url = 'https://api.indianeducation.co.in/storage/app/';

Vue.filter('formatDateTime', function(datetime) {
	if(datetime) {
		return moment.utc(datetime).format('DD MMM YYYY, hh:mm a');
	}
})

Vue.filter('formatDate', function(date) {
	if(date) {
		return moment.utc(date).format('DD MMM YYYY');
	}
})

Vue.filter('excerpt', function(text, length) {
		return text.length > length ? text.slice(0, length) + '...' : text;
})

new Vue({
  router,
  store,
  
  
  render: h => h(App)
}).$mount('#app')
